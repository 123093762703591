import {Route, Routes} from "react-router-dom";
import AuthPage from "../Components/Auth";
import ProtectedRoute from "../Components/ProtectedRoute";
import MasterDataComponent from "../Components/MasterData/MasterDataComponent";
import ExchangeRate from "../Components/MasterData/ExchangeRate";
import PaymentRequest from "../Components/PaymentRequest";
import FormNotes from "../Components/MasterData/FormNotes";
import Logout from "../Components/Logout";
import ProcessPayment from "../Components/ProcessPayment";
import PaymentList from "../Components/ProcessPayment/PaymentList";
import Departments from "../Components/MasterData/Departments";
import LogView from "../Components/LogView";
import TestSwitch from "../Components/TestSwitch";
import TravelExpense from "../Components/TravelExpense";
import CashReport from "../Components/CashReport";
import CashReportAdmin from "../Components/CashReport/CashReportAdmin";


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/auth" element={
                <AuthPage/>
            }/>
            <Route path="/logout/" element={<Logout/>}/>
            <Route path="/admin/expense"
                   element={<ProtectedRoute><MasterDataComponent section="expense_list"/></ProtectedRoute>}/>
            <Route path="/admin/season"
                   element={<ProtectedRoute><MasterDataComponent section="season_list"/></ProtectedRoute>}/>
            <Route path="/admin/account"
                   element={<ProtectedRoute><MasterDataComponent section="account_list"/></ProtectedRoute>}/>
            <Route path="/admin/personnel"
                   element={<ProtectedRoute><MasterDataComponent section="personnel_list"/></ProtectedRoute>}/>
            <Route path="/admin/exchange" element={<ProtectedRoute><ExchangeRate/></ProtectedRoute>}/>
            <Route path="/admin/notes" element={<ProtectedRoute><FormNotes/></ProtectedRoute>}/>

            <Route path="/request/:tab/:reload?" element={<ProtectedRoute><PaymentRequest/></ProtectedRoute>}/>
            <Route path="/cash_report" element={<ProtectedRoute><CashReport/></ProtectedRoute>}/>
            <Route path="/cash_report_admin" element={<ProtectedRoute><CashReportAdmin /></ProtectedRoute>}/>
            <Route path="/" element={<ProtectedRoute><PaymentRequest/></ProtectedRoute>}/>
            <Route path="/admin/request/:id" element={<ProtectedRoute><ProcessPayment/></ProtectedRoute>}/>
            <Route path="/admin/payment/list" element={<ProtectedRoute><PaymentList/></ProtectedRoute>}/>

            <Route path="/admin/department" element={<ProtectedRoute><Departments/></ProtectedRoute>}/>
            <Route path="/admin/log" element={<ProtectedRoute><LogView/></ProtectedRoute>}/>
            <Route path="/switch" element={<ProtectedRoute><TestSwitch/></ProtectedRoute>}/>

            <Route path="/travel" element={<ProtectedRoute><TravelExpense/></ProtectedRoute>}/>
        </Routes>
    )
}

export default AppRoutes