import {Button, Col, Collapse, Image, Modal, Progress, Row, Space, Statistic, Upload} from "antd";
import {
    CloseOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    InboxOutlined,
    SendOutlined
} from "@ant-design/icons";
import {UseSiteConfig} from "../../../Helpers/config-helper";
import {read, utils, writeFileXLSX} from 'xlsx';
import {useCallback, useState} from "react";
import {notify} from "../../../Helpers/toast-helper";
import {isValidDate, sleep} from "../../../Helpers/common-helper";
import GraceTimeButton from "../../GraceTimeButton";
import {API_URL} from "../../../Helpers/api-helper";
import {getAPIAppToken} from "../../../Helpers/jwt-helper";
import {IconFolderDone} from "../../Icons";

import dayjs from "dayjs";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */

const {Dragger} = Upload;

const BulkRequestForm = ({open, onClose}) => {

    const {getState} = UseSiteConfig;
    const siteConfig = getState().siteConfig;
    const fields = [
        {
            title: 'Phòng ban đề nghị',
            dataIndex: 'request_department',
            validation: (value) => siteConfig?.department?.findIndex(item => item.name === value) > -1,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Loại chi phí',
            dataIndex: 'expense_type',
            validation: (value) => siteConfig?.expense_type?.findIndex(item => item.name === value) > -1,
            getValue: (value) => value,
            template: true
        },
        /*
        {
            title: 'Tên NCC/Người thụ hưởng',
            dataIndex: 'receiver_name',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value?.toString(),
            template: true
        },

         */
        {
            title: 'Diễn giải',
            dataIndex: 'description',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value?.toString(),
            template: true
        },
        {
            title: 'Phân loại thanh toán',
            dataIndex: 'instruction',
            validation: (value) => value && value && value?.toString().trim().length > 0,
            getValue: (value) => value?.toString(),
            template: true
        },
        {
            title: 'Mã LSX/DPO/IPO',
            dataIndex: 'special_code',
            validation: null,
            getValue: (value) => value?.toString(),
            template: true
        },
        {
            title: 'Mã PO ERP',
            dataIndex: 'erp_code',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value?.toString(),
            template: true
        },
        {
            title: 'Mùa vụ',
            dataIndex: 'special_season',
            validation: null,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Số tiền đề nghị',
            dataIndex: 'request_amount',
            validation: (value) => !isNaN(value),
            getValue: (value) => Number(value),
            template: true
        },
        {
            title: 'Loại tiền',
            dataIndex: 'currency_code',
            validation: (value) => siteConfig?.rates?.findIndex(item => item.currency === value) > -1,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Số tài khoản nhận tiền',
            dataIndex: 'bank_account',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Tên tài khoản nhận tiền',
            dataIndex: 'receiver_name',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Tại ngân hàng',
            dataIndex: 'bank_name',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Tỉnh thành phố (Ngân hàng)',
            dataIndex: 'bank_province',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Chi nhánh (Ngân hàng)',
            dataIndex: 'bank_branch',
            validation: (value) => value && value?.toString().trim().length > 0,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Tỉ giá',
            dataIndex: 'exchange_rate',
            validation: null,
            getValue: (value) => Number(value),
            template: false
        },
        {
            title: 'Quy đổi VNĐ',
            dataIndex: 'foreign_to_vnd',
            validation: null,
            getValue: (value) => Number(value),
            template: false
        },
        {
            title: 'Ngày yêu cầu chi',
            dataIndex: 'request_payment_date',
            //date must be valid, format DD-MM-YYYY and must be greater or equal to today from 00:00:00AM current date
            validation: (value) => (isValidDate(value) && !dayjs(value, 'DD-MM-YYYY').isBefore(dayjs().startOf('day'))),
            getValue: (value) => dayjs(value, 'DD-MM-YYYY'),
            template: true
        },
        {
            title: 'Tài khoản đi tiền',
            dataIndex: 'transfer_from_account',
            validation: (value) => siteConfig?.transfer_from_account?.findIndex(item => item.name === value) > -1,
            getValue: (value) => value,
            template: true
        },
        {
            title: 'Kế toán nhận',
            dataIndex: 'accountant_id',
            validation: (value) => siteConfig?.personnel?.findIndex(item => item.fullname === value) > -1,
            getValue: (value) => siteConfig?.personnel?.find(item => item.fullname === value)?.uid,
            template: true
        }
    ]


    const templateDownload = useCallback(() => {
        const workbook = utils.book_new();
        const mainWorkSheet = utils.aoa_to_sheet([[]]);
        const fileName = 'Mẫu DNTT.xlsx';

        utils.sheet_add_aoa(mainWorkSheet, [fields.map(c => {
            if (c.template)
                return c.title

            return false;
        }).filter(Boolean)]);

        utils.book_append_sheet(workbook, mainWorkSheet, 'Đề nghị thanh toán');

        //remove special_type from expense_type
        const expenseTypeWorkSheet = utils.json_to_sheet(siteConfig?.expense_type?.map(item => {
            return {
                'Loại chi phí': item.name,
                'Mô tả': item.description
            }
        }));
        utils.book_append_sheet(workbook, expenseTypeWorkSheet, 'Loại chi phí');

        const special_seasonWorkSheet = utils.json_to_sheet(siteConfig?.season?.map(item => {
            return {
                'Mùa vụ': item.name,
                'Mô tả': item.description
            }
        }));
        utils.book_append_sheet(workbook, special_seasonWorkSheet, 'Mùa vụ');


        const currency_WorkSheet = utils.json_to_sheet(siteConfig?.rates?.map(item => {
            return {
                'Mã tiền': item.currency,
                'Mô tả': item.currency_name
            }
        }));
        utils.book_append_sheet(workbook, currency_WorkSheet, 'Tiền tệ');

        const account_WorkSheet = utils.json_to_sheet(siteConfig?.transfer_from_account?.map(item => {
            return {
                'Tên tài khoản': item.name,
                'Mô tả': item.description
            }
        }));
        utils.book_append_sheet(workbook, account_WorkSheet, 'Tài khoản');

        const personnel_WorkSheet = utils.json_to_sheet(siteConfig?.personnel?.map(item => {
            return item.accept_document ? {
                'Họ tên': item.fullname,
                'Vị trí': item.position_title
            } : null
        }));
        utils.book_append_sheet(workbook, personnel_WorkSheet, 'Kế toán nhận');

        writeFileXLSX(workbook, fileName);

        notify('success', 'Đã tải xuống file: ' + fileName, 3);
    }, [fields]);

    const [statusText, setStatusText] = useState('Đang chờ file...');
    const [counters, setCounters] = useState({total: 0, passed: 0, failed: 0});
    const [errorList, setErrorList] = useState([]);
    const [requestList, setRequestList] = useState([]);

    const [processingText, setProcessingText] = useState('');
    const [worker, setWorker] = useState(null);
    const [sending, setSending] = useState(false);
    const [percent, setPercent] = useState(0);
    const [done, setDone] = useState(false);

    const [buttonGraceTimeReset, setButtonGraceTimeReset] = useState(true);

    const incPassed = () => {
        setCounters((prevCounters) => ({
            ...prevCounters,
            passed: prevCounters.passed + 1,
        }))
    }

    const incFailed = () => {
        setCounters((prevCounters) => ({
            ...prevCounters,
            failed: prevCounters.failed + 1,
        }))
    }

    const incTotal = (total) => {
        setCounters((prevCounters) => ({
            ...prevCounters,
            total
        }));
    }
    const resetCounter = () => {
        setErrorList([])
        setCounters({total: 0, passed: 0, failed: 0});
        setPercent(0)
    }

    const handleFile = (info) => {
        const file = info.file.originFileObj;
        const {name} = file;

        const reader = new FileReader();

        //reset count
        resetCounter();

        reader.onload = async (event) => {
            try {
                const {result} = event.target;
                const workbook = read(result, {type: 'binary'});

                const [firstSheetName] = workbook.SheetNames;
                const worksheet = workbook.Sheets[firstSheetName];
                const json = utils.sheet_to_json(worksheet);

                //trim all string values
                json.forEach(item => {
                    Object.keys(item).forEach(key => {
                        if (typeof item[key] === "string") {
                            item[key] = item[key].trim();
                        }
                    })
                })

                if (json.length === 0) {
                    throw new Error('File không có dữ liệu');
                }

                setStatusText(<span><strong>File:</strong> {name}</span>);
                setProcessingText(`Đang kiếm tra dữ liệu...`)

                const total = json.length;
                //update total
                incTotal(total);

                //If total > 100, return error
                if (total > 50) {
                    notify('error', 'Số lượng dữ liệu quá lớn, hệ thống chỉ đáp ứng tối đa 50 dòng một lần xử lý', 3);
                    return;
                }

                setWorker(true)
                const errors = [];
                const localCounter = {
                    passed: 0,
                    failed: 0
                }
                for (const [index, item] of json.entries()) {

                    let validationStatus = true;
                    let errorField = [];
                    fields.forEach(field => {
                        if (field.validation) {
                            if (!field.validation(item[field.title])) {
                                errorField.push(field.title);
                                validationStatus = false
                            }
                        }
                    })

                    //check for special case
                    const expense_type = siteConfig?.expense_type?.find(expense => expense.name === item['Loại chi phí']);
                    if (expense_type?.special_type === 1) {
                        //Special case, these cases need special_code and special_season, check for undefined or empty string
                        if (!item['Mã LSX/DPO/IPO'] || item['Mã LSX/DPO/IPO'].length === 0) {
                            errorField.push('Mã LSX/DPO/IPO');
                            validationStatus = false
                        }

                        if (siteConfig?.season?.findIndex(s => s.name === item['Mùa vụ']) < 0) {
                            errorField.push('Mùa vụ');
                            validationStatus = false
                        }
                    }

                    //If currency_code is not VND, check for exchange_rate
                    let foreign_to_vnd = item['Số tiền đề nghị'];
                    let exchange_rate = 1;
                    if (item['Loại tiền'] !== 'VND') {
                        //get currency rate from siteConfig
                        let currency_rate = parseFloat(siteConfig?.rates?.find(rate => rate.currency === item['Loại tiền'])?.sell?.toString().replace(/,/g, ''));

                        if (isNaN(currency_rate)) {
                            errorField.push('Lỗi tỉ giá');
                            validationStatus = false
                        } else {
                            //currency_rate = isNaN(currency_rate) ? 1 : currency_rate;
                            //convert to VND
                            foreign_to_vnd = item['Số tiền đề nghị'] * currency_rate;
                            exchange_rate = currency_rate;
                        }
                    }

                    //add foreign_to_vnd to item
                    json[index]['Quy đổi VNĐ'] = foreign_to_vnd;
                    json[index]['Tỉ giá'] = exchange_rate;

                    //update request_payment_date to Date Object
                    json[index]['Ngày yêu cầu chi'] = dayjs(item['Ngày yêu cầu chi'], 'DD-MM-YYYY').toDate();

                    //if errorField is not empty, push to errors
                    if (errorField.length > 0) {
                        errors.push({
                            line: index + 2,
                            fields: errorField
                        })
                    }

                    validationStatus ?
                        incPassed()
                        :
                        incFailed();

                    //update local counter based on result of validationStatus
                    validationStatus ?
                        localCounter.passed++
                        :
                        localCounter.failed++

                    setProcessingText(`Đang kiếm tra dữ liệu ${index + 1}/${total}`)
                    setPercent((((localCounter.failed + localCounter.passed) / total) * 100).toFixed(0))

                    //sleep random time from 150ms to 500ms
                    await sleep(Math.floor(Math.random() * 30) + 120);
                }

                if (errors.length > 0) {
                    setErrorList(errors)
                } else {
                    setRequestList(json)
                }

                setWorker(null);
                setProcessingText(`Kiểm tra dữ liệu hoàn tất`)

            } catch (e) {
                console.log(e);
                notify('error', 'Lỗi khi đọc file: ' + e.message, 3);
            }

        }

        reader.readAsArrayBuffer(file);
    }

    const fileUploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        maxCount: 1,
        accept: ".xlsx,.xls",
        customRequest: (file) => {
            return null;
        },
        onChange: handleFile,
    }

    const handleClose = () => {
        if (sending)
            return;

        resetCounter();
        setButtonGraceTimeReset(!buttonGraceTimeReset)
        setWorker(null)
        setSending(false)
        setStatusText('Đang chờ file...');
        setDone(false);
        setRequestList([])
        setErrorList([])
        onClose();
    }

    const handleCreateRequest = async () => {
        const token = getAPIAppToken()
        //transform requestList to requestListData

        const requestListData = requestList.map(item => {
            return {
                request_department: item['Phòng ban đề nghị'],
                expense_type: item['Loại chi phí'],
                //receiver_name: item['Tên NCC/Người thụ hưởng'],
                description: item['Diễn giải'],
                instruction: item['Phân loại thanh toán'].toString(),
                special_code: item['Mã LSX/DPO/IPO'],
                erp_code: item['Mã PO ERP'],
                special_season: item['Mùa vụ'],
                request_amount: item['Số tiền đề nghị'],
                currency_code: item['Loại tiền'],

                bank_account: item['Số tài khoản nhận tiền'],
                receiver_name: item['Tên tài khoản nhận tiền'],
                bank_name: item['Tại ngân hàng'],
                bank_province: item['Tỉnh thành phố (Ngân hàng)'],
                bank_branch: item['Chi nhánh (Ngân hàng)'],

                exchange_rate: item['Tỉ giá'],
                foreign_to_vnd: item['Quy đổi VNĐ'],
                request_payment_date: item['Ngày yêu cầu chi'],
                transfer_from_account: item['Tài khoản đi tiền'],
                accountant_id: siteConfig.personnel.find(personnel => personnel.fullname === item['Kế toán nhận'])?.uid
            }
        });

        //Begin posting requestListData
        setSending(true);
        setProcessingText('Đang tạo yêu cầu...');

        resetCounter();

        let counter = 0;
        incTotal(requestListData.length)
        for (const [key, request] of requestListData.entries()) {
            //Set default request status to 'THẤT BẠI'

            try {
                const response = await fetch(`${API_URL}/request/add`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify(request)
                })

                //console.log("Status:", response.status, "Data:", await response.json())
                const {data} = await response.json();
                if (response.status === 200) {
                    incPassed();
                    request.request_status = data;
                    console.log("Inserted:", data);
                } else {
                    request.request_status = 'THẤT BẠI';
                    incFailed();
                }
            } catch (e) {
                console.log(e);
                request.request_status = 'THẤT BẠI';
                incFailed();
            }

            setProcessingText(`Đang xử lý ${key + 1}/${requestListData.length} yêu cầu`);

            await sleep(Math.floor(Math.random() * 200) + 500)
            counter++
            setPercent((((counter + 1) / requestListData.length) * 100).toFixed(0))
        }

        navigator.sendBeacon('https://app-service.forlife.vn/thanhtoan/info/server/beacon', JSON.stringify({
            data: {
                request_list: requestListData
            },
            type: 'bulkCreate',
            category: 'request',
        }));

        setProcessingText('Đã tạo xong yêu cầu')
        //console.log(requestListData);
        setSending(false);
        setDone(true);
    }

    return (
        <Modal
            title="Tạo nhiều yêu cầu thanh toán"
            width={600}
            open={open}
            onCancel={handleClose}
            cancelText="Đóng"
            okText="Tạo yêu cầu"
            maskClosable={false}
            footer={
                <Space>
                    <Button
                        icon={<CloseOutlined/>}
                        onClick={handleClose}
                        disabled={sending}
                    >
                        Đóng
                    </Button>
                    <GraceTimeButton
                        inputGraceTime="5"
                        text="Tạo yêu cầu"
                        icon={<SendOutlined/>}
                        disabled={!((counters.total) > 0 && (counters.passed === counters.total)) || worker !== null || sending || done}
                        reset={buttonGraceTimeReset}
                        onClickAction={handleCreateRequest}
                    />
                </Space>
            }

        >
            <Row className="mt-4" gutter={16}>
                <Col span={10}>
                    <Space direction="vertical" className="text-center">
                        <Dragger {...fileUploadProps}
                                 disabled={worker || sending || done}
                                 style={{background: 'white'}}
                        >
                            <div className="m-2">
                                <div style={{opacity: (worker || sending || done) ? 0 : 100, position: 'relative'}}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <span className="ant-upload-text">Kéo thả hoặc click để chọn file</span>
                                </div>
                                <div style={{
                                    opacity: worker ? 100 : 0,
                                    position: 'absolute',
                                    top: 20,
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '100%'
                                }}>
                                    <Image src="/data-processing-100.gif" preview={false} width={100}/>
                                </div>
                                <div style={{
                                    opacity: sending ? 100 : 0,
                                    position: 'absolute',
                                    top: 20,
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '100%'
                                }}>
                                    <Image src="/dove100.gif" preview={false} width={100}/>
                                </div>
                                <div style={{
                                    opacity: done ? 100 : 0,
                                    position: 'absolute',
                                    top: 20,
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '100%'
                                }}>
                                    <IconFolderDone/>
                                </div>
                            </div>
                        </Dragger>
                        <Button type="text" icon={<DownloadOutlined/>}
                                onClick={(e) => {
                                    e.preventDefault();
                                    templateDownload();
                                }}
                        >Tải về file
                            mẫu</Button>
                    </Space>
                </Col>
                <Col span={14}>
                    <Space direction="vertical" className="w-100">
                        <div>{statusText}</div>
                        <Space
                            direction="horizontal"
                            style={{display: counters.total > 0 ? 'inline-block' : 'none'}}
                        >
                            <span>{processingText}</span>
                            <Progress
                                status="active"
                                //Percentage of sum of passed and failed / total
                                percent={percent}
                                style={{width: '280px'}}
                            />
                            <Row className="w-100">
                                <Col span={1}></Col>
                                <Col span={7}>
                                    <Statistic title="Tổng" value={counters.total}/>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={7}>
                                    <Statistic title="Đạt" value={counters.passed} valueStyle={{color: '#1677ff'}}/>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={7}>
                                    <Statistic title="Lỗi" value={counters.failed} valueStyle={{color: '#cf1322'}}/>
                                </Col>
                            </Row>
                            {/*
                            <Button type="text" className="mt-1" icon={<FileDoneOutlined/>} style={{display: counters.failed > 0 ? 'block' : 'none'}}>Xem chi tiết lỗi</Button>
                            */}
                        </Space>
                    </Space>
                </Col>
            </Row>
            <Row>
                <hr/>
                <Collapse
                    size="small"
                    className="w-100 rounded-3"
                    style={{
                        maxHeight: '200px',
                        overflow: 'auto',
                        display: errorList.length > 0 ? 'block' : 'none'
                    }}
                    items={
                        errorList.map((item, index) => {
                            return (
                                {
                                    key: index,
                                    label: `Dòng ${item.line}`,
                                    extra: <Space
                                        style={{color: 'lightcoral'}}><ExclamationCircleOutlined/><span>Lỗi</span></Space>,
                                    children: (
                                        <ul>
                                            {item.fields.map((field, index) => {
                                                    return (
                                                        <li key={index}>{field}</li>
                                                    )
                                                }
                                            )}
                                        </ul>
                                    )
                                }
                            )
                        })
                    }
                />
            </Row>
        </Modal>
    )
}

export default BulkRequestForm;
