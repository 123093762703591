import {Button, Flex, Popover, Space, Table, Upload} from "antd";
import {DownloadOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {useMemo, useState} from "react";
import PaymentLineInput from "./PaymentLineInput";
import {notify} from "../../Helpers/toast-helper";
import {line_columns, rowHelper, tranfrormData} from "./helper";
import {read, utils, writeFileXLSX} from 'xlsx';

const PaymentLine = ({config, poTags, setPoTags, lineData, setLineData, setOccasionCode}) => {

    const [lineEditOpen, setLineEditOpen] = useState(false)
    const [fileList, setFileList] = useState([])

    const TaleFooter = () => {
        return (
            <Flex
                justify="space-between"
            >
                <Space>
                    <Popover
                        content="Tải lên dữ liệu cho các  'Chi phí khác' từ file excel"
                        trigger="hover"
                        arrow={false}
                        placement={"bottom"}
                    >
                        <Upload
                            onChange={handleFileChange}
                            showUploadList={false}
                            customRequest={() => {
                                return false
                            }}
                            accept={".xlsx"}
                            //Limit the file size to 1MB
                            beforeUpload={file => {
                                if (file.size > 1024 * 1024) {
                                    notify('error', 'File quá lớn, vui lòng chọn file dưới 1MB')
                                    return false
                                }

                                return true
                            }}
                            fileList={fileList}
                        >
                            <Button
                                icon={<UploadOutlined/>}
                                size="small"
                            />
                        </Upload>
                    </Popover>
                    <Popover
                        content="Tải về file template mẫu sử dụng cho các 'Chi phí khác'"
                        trigger="hover"
                        arrow={false}
                        placement={"bottom"}
                    >
                        <Button
                            icon={<DownloadOutlined/>}
                            title="Tải mẫu"
                            size="small"
                            onClick={generateExcelTemplate}
                        />
                    </Popover>
                </Space>
                <Button
                    size="small"
                    icon={<PlusOutlined/>}
                    type="primary"
                    onClick={() => setLineEditOpen(true)}
                />
            </Flex>
        )
    }

    const addALine = async (data) => {
        //currency_code must be consistent with other lineData, if not, ignore the input
        if (lineData.length > 0 && lineData[0].currency_code !== data.currency_code) {
            notify('error', 'Không thể thêm dòng mới với loại tiền khác')
            return
        }

        //Add a key to the data
        data.key = Date.now()
        //The timestamp is used to make sure the FIFO order is kept

        data.timestamp = Date.now()

        //wait for 3ms to prevent the same key
        await new Promise(r => setTimeout(r, 3))

        setLineData([...lineData, data])

        if (!data.poCode || data.poCode.trim().length === 0) {
            return
        }

        //Check if data.poCode is already in the poTags, if not, add it otherwise do nothing
        if (!poTags.includes(data.poCode)) {
            setPoTags([...poTags, data.poCode])
        }

        if (data?.occasion_code) {
            setOccasionCode(data.occasion_code)
        }
    }

    const deleteALine = (index) => {

        let lineToBeDeleted = lineData.find(item => item.index === index)
        //Delete an array item by index and shift the rest of the array
        const newData = lineData.filter((item) => item.index !== index)
        setLineData(newData)

        if (!lineToBeDeleted?.poCode || lineToBeDeleted.poCode.trim().length === 0) {
            return
        }

        //Remove the poCode from poTags if there is no lineData associated with that poCode

        if (!newData.find(item => item.poCode === lineToBeDeleted.poCode)) {
            setPoTags(poTags.filter(item => item !== lineToBeDeleted.poCode))
        }


    }

    const tableData = useMemo(() => {
        return tranfrormData(lineData)
    }, [lineData])

    const excelCols = ['Diễn giải', 'Số lượng', 'Đơn giá', 'Thành tiền', 'Ghi chú', 'Mã PO']
    const requiredExcelCols = ['Diễn giải', 'Số lượng', 'Đơn giá', 'Thành tiền', 'Mã PO']

    function generateExcelTemplate() {
        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet([
            excelCols // Header row
        ]);
        utils.book_append_sheet(workbook, worksheet, 'Mẫu - ĐNTT');
        writeFileXLSX(workbook, 'Mẫu bảng kê ĐNTT.xlsx');
    }

    const sanitizeExcelData = (value) => {

        //If value is a string, trim it and limit the length to 512 characters
        if (typeof value === "string") {
            value = value.trim().substring(0, 512);
        }

        //If value is a number, round it to 2 decimal places
        if (typeof value === "number") {
            value = isNaN(value) ? 0 : Number(value.toFixed(2));
        }

        return value;

    }

    const handleFileChange = async (info) => {
        //return if the file is empty

        if (!info?.file?.originFileObj)
            return

        const file = info.file.originFileObj;
        const reader = new FileReader();

        reader.onload = async (event) => {
            try {
                const {result} = event.target;
                const workbook = read(result, {type: 'binary'});

                const [firstSheetName] = workbook.SheetNames;
                const worksheet = workbook.Sheets[firstSheetName];
                const json = utils.sheet_to_json(worksheet, {
                    blankrows: true,
                });

                //Limit to maximum 100 rows
                if (json.length > 300) {
                    notify('error', 'Dữ liệu quá lớn, vui lòng thiết lập dữ liệu tối đa 300 dòng')
                    return
                }

                //trim all string values
                json.forEach(item => {
                    Object.keys(item).forEach(key => {
                        item[key] = sanitizeExcelData(item[key]);
                    })
                })

                if (json.length === 0) {
                    notify('error', 'File không có dữ liệu')
                    return
                }

                //Get the first row and validate the header
                const header = Object.keys(json[0]);

                if (!requiredExcelCols.every((value) => header.includes(value))) {

                    notify('error', 'File không đúng định dạng')
                    return
                }

                //Add rows to lineData

                async function asyncForEach(item) {
                    let lines = []
                    let newPOs = []

                    for (let index = 0; index < item.length; index++) {

                        //Validate the data, first 2 columns must be not empty, if either of them is empty, skip the row
                        if (!item[index][excelCols[0]] || !item[index][excelCols[1]]) {
                            continue
                        }

                        const amount = isNaN(item[index][excelCols[3]]) ? 0 : Number(item[index][excelCols[3]])
                        let line = {
                            description: item[index][excelCols[0]],
                            quantity: item[index][excelCols[1]],
                            price: item[index][excelCols[2]],
                            total_amount: amount,
                            amount: amount,
                            note: item[index][excelCols[4]],
                            currency_code: 'VND',
                            poCode: item[index][excelCols[5]],
                            expense_type: `others`,
                            key: Date.now(),
                            timestamp: Date.now()
                        }

                        //Sleep for 3ms to prevent the same key
                        await new Promise(r => setTimeout(r, 3))

                        newPOs.push(line.poCode)
                        lines.push(line)
                    }

                    setLineData([...lineData, ...lines])
                    setPoTags([...poTags, ...new Set(newPOs)])
                }

                await asyncForEach(json)
            } catch (e) {
                notify('error', 'Lỗi khi đọc file', e.message)
            }
        }

        try {
            reader.readAsArrayBuffer(file);
        } catch (e) {
            notify('error', 'Lỗi khi đọc file', e.message)
        }
    };

    return (
        <>
            <Table
                columns={line_columns(lineData, deleteALine)}
                dataSource={tableData}
                size="small"
                footer={() => TaleFooter()}
                pagination={false}
                bordered={false}
                onRow={(record) => {
                    return rowHelper(record)
                }}
            />
            <PaymentLineInput open={lineEditOpen} setOpen={setLineEditOpen} onAddData={addALine} config={config}/>
        </>
    )
}

export default PaymentLine;
