import {Button, DatePicker, Flex, Space, Table} from "antd";
import {useCallback, useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import dayjs from "dayjs";
import {utils, writeFileXLSX} from "xlsx";
import {notify} from "../../Helpers/toast-helper";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import {formatNumber} from "../../Helpers/number-helper";

const CashReportAdmin = () => {
    const columns = [
        {
            title: 'Cửa hàng',
            dataIndex: 'store',
            key: 'store',
        },
        {
            title: 'Người báo cáo',
            dataIndex: 'reporter',
            key: 'reporter',
        },
        {
            title: 'Vị trí',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Ngày báo cáo',
            dataIndex: 'report_date',
            key: 'report_date',
            render: (text) => {
                return dayjs(text).format('YYYY-MM-DD');
            }
        },
        {
            title: 'Số tiền',
            key: 'money',
            dataIndex: 'money',
            render: (text) => (
                <span>{formatNumber(text, 0)}</span>
            )
        },
        {
            title: 'Thời gian gửi',
            dataIndex: 'datetime',
            key: 'datetime',
            render: (text) => {
                return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    ]

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [pickedDate, setPickedDate] = useState(dayjs().format('DD-MM-YYYY'));

    const fetchData = (date) => {
        setLoading(true);
        api.post(`/store/admin/cash`, {date: date}).then(response => {
            if (response) {
                setData(response)
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchData(dayjs().format('DD-MM-YYYY'));
    }, [])

    const reload = () => {
        fetchData(pickedDate)
    }

    const exportFile = useCallback(() => {
            const exportData = data?.map((item) => {
                return {
                    'Cửa hàng': item.store,
                    'Người báo cáo': item.reporter,
                    'Vị trí': item.job_title,
                    'TM': item.money,
                    'Ngày báo cáo': dayjs(item.report_date).format('YYYY-MM-DD'),
                    'Thời gian gửi': dayjs(item.datetime).format('YYYY-MM-DD HH:mm:ss')
                }

            })
            const ws = utils.json_to_sheet(exportData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Bao cao TM");

            //Generate file name based on date
            const date = new Date();
            const fileName = `Bao cao TM CH ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`;
            writeFileXLSX(wb, fileName);

            notify('success', 'Đã tải xuống file: ' + fileName, 3);
        }, [data]
    )

    return (
        <>
            <h1>Danh sách báo cáo TM cửa hàng</h1>
            <Flex direction="row" justify="space-between" className="mb-3">

                <Space>
                    <span>Chọn ngày báo cáo</span>

                    <DatePicker
                        format="DD-MM-YYYY"
                        defaultValue={dayjs()}
                        onChange={(date) => {setPickedDate(dayjs(date).format('DD-MM-YYYY'));}}
                    />
                    <Button icon={<SearchOutlined />} type="primary" onClick={reload}>Báo cáo</Button>
                </Space>
                <Button type="default" icon={<ExportOutlined />} onClick={exportFile}>Xuất Excel</Button>
            </Flex>
            <Table
                columns={columns}
                dataSource={data}
                rowKey={record => record._id}
            />
        </>
    )
}

export default CashReportAdmin;