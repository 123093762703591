import {
    Alert, AutoComplete, Badge,
    Button, Collapse,
    DatePicker,
    Drawer, Flex,
    Form,
    Input,
    InputNumber,
    Popover,
    Select,
    Skeleton,
    Space,
    Tag,
} from "antd";
import {useCallback, useEffect, useState} from "react";
import {UseLoading} from "../../Helpers/loading-spinner-helper";
import api from "../../Helpers/api-helper";
import {
    CloseOutlined, CopyOutlined, PrinterOutlined, ProfileOutlined,
    QuestionCircleOutlined,
    SearchOutlined,
    SendOutlined
} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import {useUserInfo} from "../../Helpers/user-session-helper";
import {REQUEST_STATUS} from "./const";
import {calculateForeignToVND, OptionRender, OptionsWithDescription} from "../../Helpers/common-helper";
import {UseSiteConfig} from "../../Helpers/config-helper";
import {formatNumber} from "../../Helpers/number-helper";
import {IconStars} from "../Icons";
import BulkRequestForm from "./BulkRequestForm/BulkRequestForm";

import dayjs from "dayjs";
import debounce from "lodash/debounce";
import POTags from "./POTags";
import PaymentLine from "./PaymentLine";
import {removeVietnameseAccents} from "./helper";
import FormPrintPreview from "./FormPrintPreview";
import RequestClone from "./RequestClone";

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');
 */


const {Option} = Select;
const {TextArea} = Input;

const PaymentRequestForm = ({open, onClose, onAdded, editData}) => {
    const setLoading = UseLoading(state => state.setLoading);
    const [config, setConfig] = useState(null);
    const [specialCase, setSpecialCase] = useState([]);
    const [isSpecialCase, setIsSpecialCase] = useState('none');
    const [isExchange, setIsExchange] = useState('none');
    const [actionButtonEnabled, setActionButtonEnabled] = useState(true);

    const [bankInfoDisable, setBankInfoDisable] = useState(false);
    const [foreignBankActive, setForeignBankActive] = useState(false);

    const [pasteModalOpen, setPasteModalOpen] = useState(false);

    const [cloneRequestOpen, setCloneRequestOpen] = useState(false);
    const [note, setNote] = useState(null);

    const [partnerLoading, setPartnerLoading] = useState(false);
    const [partnerData, setPartnerData] = useState([]);
    const [poTags, setPoTags] = useState([]);
    const [lineData, setLineData] = useState([])

    const [printPreviewOpen, setPrintPreviewOpen] = useState(false);

    //This state using for print preview, user must submit form before print, otherwise, it will be using default form data
    const [immutableFormData, setImmutableFormData] = useState(null);

    const [form] = Form.useForm();
    const exchangeRate = Form.useWatch('exchange_rate', form);
    const userInfo = useUserInfo();
    const {siteConfig} = UseSiteConfig();

    const isCashPayment = ['TM', 'TCH']

    const handleClose = () => {
        onClose();
    }

    const updateFormData = (response) => {
        response.request_payment_date = dayjs(response.request_payment_date).isValid() ? dayjs(response.request_payment_date) : dayjs();
        form.setFieldsValue(response);

        setImmutableFormData(response);

        setIsExchange((!response?.currency_code || (response?.currency_code === 'VND')) ? 'none' : 'block');

        //check if special case
        //console.log(specialCase, specialCase.some(item => item.name === response.expense_type))
        setIsSpecialCase(specialCase.some(item => item.name === response.expense_type) ? 'block' : 'none');

        //check if note is not null, set note to state
        if (response?.approval_note) {
            setNote(response.approval_note);
        }

        const poTags = response?.erp_code?.split(',').map(item => item.trim()) || [];
        if (poTags.length > 0) {
            setPoTags(poTags);
        }

        if (response?.payment_lines) {
            setLineData(response.payment_lines);
        }

        //if status is equal to 'new', enable the submit button
        if ((response.request_status !== REQUEST_STATUS.new.code) && (response.request_status !== REQUEST_STATUS.return_to_requester.code)) {
            setActionButtonEnabled(false)
        } else {
            setActionButtonEnabled(true)
        }
    }

    useEffect(() => {
        if (open) {

            //if editData is not null, get form data from endpoint
            if (editData) {
                setLoading(true);
                api.post(`/request/user/get`, {_id: editData._id}).then((response) => {
                    if (response) {
                        updateFormData(response);
                        console.log(response.request_amount)
                    }
                }).finally(() => {
                    setLoading(false)
                });

            } else {
                setIsSpecialCase('none')
            }
        }
    }, [editData]);

    useEffect(() => {
        if (!siteConfig)
            return

        setConfig(siteConfig);
    }, [siteConfig]);

    useEffect(() => {
        if ((!config) || (config && Object.keys(config).length === 0))
            return;

        setActionButtonEnabled(true);

        const specialCase = config.expense_type.filter(item => item.special_type === 1);
        setSpecialCase(specialCase);

        return () => {
            form.resetFields();
            setIsExchange('none');
            setIsSpecialCase('none');
            setPoTags([]);
            setLineData([]);
            setNote(null)
        }
    }, [config, open]);

    useEffect(() => {
        let reloadCurrency = 'VND'
        //set currency_code matching with the first lineData
        if (lineData.length > 0) {
            form.setFieldsValue({
                currency_code: lineData[0].currency_code
            })

            if (lineData[0].currency_code !== 'VND')
                reloadCurrency = lineData[0].currency_code
        }

        const isVND = form.getFieldValue('currency_code') === 'VND';

        //set total amount equal to sum of all lineData
        const totalAmount = lineData.reduce((total, item) => total + item.amount, 0);
        if (lineData.length > 0) {
            form.setFieldsValue({
                request_amount: isNaN(totalAmount) ? 0 : Number(totalAmount.toFixed(isVND ? 0 : 2))
            })
        } else {
            form.setFieldsValue({
                request_amount: 0
            })
        }

        if (reloadCurrency)
            handleCurrencyChange(reloadCurrency)

        //handleRequestAmountChange()
    }, [lineData])


    const debouncedSearchPartner = useCallback(
        debounce((query) => {
            if (partnerLoading)
                return;

            setPartnerLoading(true)
            api.post(`/odoo/partner`, {name: query})
                .then(response => {
                    if (response && Array.isArray(response)) {
                        setPartnerData(response.map(item => ({
                            value: item.display_name,
                            label: item.display_name,
                        })))
                    }
                })
                .finally(() => {
                    setPartnerLoading(false)
                })
        }, 1000),
        []
    );

    const reformatNoteContent = (content) => {
        if (!content)
            return null;

        const lines = content.split('\n');
        //Display lines, add <br/> tag to the end of each line
        return lines.map((line, index) => (
            <span key={index}>
                {line}<br/>
            </span>
        ));
    }

    const onExpenseTypeSelect = (value) => {
        //Check if value existed in specialCase array
        const isSpecialCase = specialCase.some(item => item.name === value);
        if (isSpecialCase) {
            //This is special case, set display of other required fields to show
            setIsSpecialCase('block');

        } else {
            setIsSpecialCase('none');
            //reset those speical fields value
            form.setFieldsValue({
                special_code: null,
                special_season: null
            });
        }
    }

    const CurrencyOptions = (options, onChange) => {
        if (!options)
            return null

        return (
            <Form.Item
                name="currency_code"
                style={{marginBottom: 0}}
                initialValue="VND"
            >
                <Select
                    style={{width: 80, maxHeight: '30px'}}
                    onSelect={onChange}
                >
                    {options.map((item, index) => (
                        <Option key={index} value={item.currency}>
                            {item.currency}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        )
    }

    const handleRequestAmountChange = (value) => {
        const currencyCode = form.getFieldValue('currency_code');
        const exchangeRate = form.getFieldValue('exchange_rate');

        if (currencyCode && exchangeRate) {
            const convertedAmount = calculateForeignToVND(value, exchangeRate);

            form.setFieldsValue({
                foreign_to_vnd: convertedAmount
            })
        }
    }

    const handleCurrencyChange = (value) => {
        if (value === 'VND') {
            setIsExchange('none');

            form.setFieldsValue({
                exchange_rate: null,
                foreign_to_vnd: null
                //currency_code: null
            });
        } else {
            setIsExchange('block');
            //get exchange rate data from config
            const exchangeRate = config.rates.find(item => item.currency === value);

            const convertedAmount = calculateForeignToVND(form.getFieldValue('request_amount'), exchangeRate.sell);
            form.setFieldsValue({
                //currency_code: exchangeRate.currency,
                exchange_rate: exchangeRate.sell,
                foreign_to_vnd: convertedAmount
            })
        }
    }

    const handleSubmit = (print = false) => {
        if (poTags.length > 0)
            form.setFieldValue('erp_code', poTags.join(', '))

        //validate form before process
        form.validateFields().then((values) => {
            //validate request_amount, if not number return error
            if (isNaN(values.request_amount)) {
                notify('error', 'Số tiền đề nghị thanh toán không hợp lệ');
                return;
            }

            values.request_payment_date = dayjs(values.request_payment_date).toDate();
            values.accountant_id = parseInt(values.accountant_id);
            //remove comma from exchange rate
            values.exchange_rate = parseFloat(values.exchange_rate?.toString().replace(/,/g, ''));

            //if currency_code is VND, set exchange rate to 1 and set foreign_to_vnd equal to request_amount
            if (values.currency_code === 'VND') {
                values.exchange_rate = 1;
                values.foreign_to_vnd = values.request_amount;
            }

            //if editData is not null, add _id to values
            if (editData) {
                values._id = editData._id;
            }

            //Nếu phương thức thanh toán là Tiền mặt (TM) thì thông tin ngân hàng sẽ không bắt buộc

            //if (values.transfer_from_account !== 'TM' || values.transfer_from_account !== 'TCH' || values.bank_name === 'Ngân hàng nước ngoài') {
            if (!isCashPayment.includes(values.transfer_from_account) || values.bank_name === 'Ngân hàng nước ngoài') {
                //Kiểm tra các thông tin về tài khoản ngân hàng
                const isForeignBank = values.bank_name === 'Ngân hàng nước ngoài'

                const required_fields = ['bank_account', 'receiver_name', 'bank_name']

                if (!isForeignBank) {
                    required_fields.push('bank_province', 'bank_branch')
                }

                const required_fields_description = {
                    bank_account: 'Số tài khoản ngân hàng',
                    receiver_name: 'Tên tài khoản nhận tiền',
                    bank_name: 'Ngân hàng',
                    bank_province: 'Tỉnh thành phố (Ngân hàng)',
                    bank_branch: 'Chi nhánh (Ngân hàng)'
                }

                let isError = false

                for (const field of required_fields) {
                    //get type of field, if it is string, trim it
                    const field_value = typeof values[field] === 'string' ? values[field].trim() : values[field]
                    if (!field_value || field_value === '') {
                        notify('error', 'Vui lòng nhập đầy đủ thông tin: ' + required_fields_description[field]);
                        isError = true
                        break
                    }
                }

                if (isError) return
            } else {
                //Reset bank info
                values.bank_account = null;
                values.receiver_name = null;
                values.bank_name = null;
                values.bank_province = null
                values.bank_branch = null
            }

            //Add lineData to values
            if (!print)
                values.payment_lines = lineData;

            if (print) {
                return setPrintPreviewOpen(true);
            }

            //If not print, remove several fields and submit
            delete values.created_by_name
            delete values.request_code

            setLoading(true);
            api.post('/request/add', values).then((response) => {
                if (response) {
                    notify('success', response);
                    onAdded();

                    handleClose();
                }
            }).finally(() => {
                setLoading(false);
            });
        }).catch((error) => {
            //console.log(error)
            //notify('error', 'Vui lòng kiểm tra lại thông tin');
        })
    }

    if (!config)
        return (<Skeleton active/>)


    const handlePaste = (pastedData) => {
        setPasteModalOpen(false);
        //console.log(pastedData)
        //console.log(siteConfig)

        //Validate pasted data
        //Check if currency_code is valid by comparing it with config.rates
        const currencyCode = siteConfig.rates.find(item => item.currency === pastedData.currency_code);
        if (!currencyCode) {
            //set currency_code to VND
            pastedData.currency_code = 'VND';
        }

        //Validate special_season if special_code is not null or blank
        if (pastedData.special_season) {
            const specialCase = siteConfig.season.find(item => item.name === pastedData.special_season);
            if (!specialCase) {
                pastedData.special_season = null;
            }
        }

        form.setFieldsValue(pastedData);

        handleRequestAmountChange(pastedData.request_amount);

        //if currency_code is not VND, set exchange rate and foreign_to_vnd
        if (pastedData.currency_code !== 'VND') {
            handleCurrencyChange(pastedData.currency_code);
            setIsExchange((!pastedData?.currency_code || (pastedData?.currency_code === 'VND')) ? 'none' : 'block');
        }
    }

    const handlePartnerSearch = (value) => {
        if (value && value.trim().length >= 3)
            debouncedSearchPartner(value.trim())
    }

    const removeAccents = (e) => {
        const value = e.target.value.trim();
        form.setFieldsValue({receiver_name: removeVietnameseAccents(value).toUpperCase()})
    }

    const setOccasionCode = (code) => {
        const expenseType = form.getFieldValue('expense_type');

        if (specialCase.some(item => item.name === expenseType)) {
            form.setFieldsValue({special_code: code})
        }
    }

    return (
        <div>
            <Drawer
                title="Đề nghị thanh toán"
                placement="right"
                onClose={handleClose}
                open={open}
                width={980}
                maskClosable={false}
                extra={
                    <Space>
                        <Popover
                            content={
                                <RequestClone
                                    open={cloneRequestOpen}
                                    setOpen={setCloneRequestOpen}
                                    setData={updateFormData}
                                />
                            }
                            arrow={false}
                            placement="bottomRight"
                            trigger="click"
                            open={cloneRequestOpen}
                            onOpenChange={setCloneRequestOpen}
                        >
                            <Button type="text" icon={<CopyOutlined/>}/>
                        </Popover>
                        <Button type="text" icon={<IconStars/>} onClick={setPasteModalOpen}/>
                    </Space>
                }
            >
                <Form
                    labelCol={{
                        span: 8,
                        style: {
                            textAlign: 'left'
                        }
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    form={form}
                    layout="horizontal"
                >
                    <Form.Item
                        style={{display: editData ? 'block' : 'none'}}
                        label="Mã đề nghị"
                        name="request_code"
                        initialValue=""
                    >
                        <Input disabled value={editData?.request_code} rootClassName="text-bold text-danger"/>
                    </Form.Item>
                    <Form.Item
                        style={{display: editData ? 'block' : 'none'}}
                        label="Tình trạng"
                    >
                        <Tag
                            color={REQUEST_STATUS[editData?.request_status]?.color}>{REQUEST_STATUS[editData?.request_status]?.label}</Tag>
                        {
                            note && editData?.request_status === REQUEST_STATUS.return_to_requester.code ?
                                <Alert className="mt-2" message={note} type="warning"/> : null
                        }
                    </Form.Item>
                    <Form.Item
                        name="request_department"
                        label="Phòng ban đề nghị"
                        initialValue={userInfo.department}
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn phòng ban đề nghị'
                            }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={
                                config?.department?.map(item => ({
                                    label: item.name,
                                    value: item.name
                                }))
                            }
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Loại chi phí"
                        name="expense_type"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn loại chi phí'
                            }]}
                    >
                        <Select
                            onSelect={onExpenseTypeSelect}
                            optionLabelProp="value"
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {OptionsWithDescription(config?.expense_type, {label: 'name', description: 'description'})}
                        </Select>
                    </Form.Item>

                    <div>
                        <Collapse
                            expandIconPosition="end"
                            className="mb-3 w-100"
                            size="small"
                            defaultActiveKey={['-1']}
                            items={[
                                {
                                    key: '1',
                                    label:
                                        <Space>
                                            <ProfileOutlined
                                                style={{fontSize: '1.1em'}}
                                            />
                                            <span className="text-primary text-bold"> Bảng kê chi phí</span>
                                            {/*}
                                            <Popover
                                                content={<div>Dùng để kê khai chi tiết các nội dung chi phí.<br/>Sau đó
                                                    có
                                                    thể in Đề Nghị Thanh Toán trực tiếp<br/>thay cho việc phải điền vào
                                                    file
                                                    Excel như trước kia</div>}
                                            >
                                                <Tag
                                                    color="red"
                                                    icon={<QuestionCircleOutlined/>}
                                                />
                                            </Popover>
                                            {*/}
                                        </Space>,
                                    children: <PaymentLine config={config} poTags={poTags} setPoTags={setPoTags}
                                                           lineData={lineData} setLineData={setLineData}
                                                           setOccasionCode={setOccasionCode}/>,
                                    extra: <Badge
                                        count={lineData.filter(line => !line.key.toString().startsWith('summary')).length}/>
                                },
                            ]}
                        />
                    </div>

                    <Form.Item
                        label={<span>Mã PO trên Odoo ERP&nbsp;
                            {/*
                            <Popover
                                //content={reformatNoteContent("Bấm + để nhập mã PO,\nCó thể nhập nhiều mã PO một lúc bằng cách dán vào danh sách\nmã PO, phân tách bằng khoảng trắng, dấu phẩy hoặc xuống hàng.\nVí dụ: PVD001,PDV002,PVD007")}
                                title="Mã PO trên Odoo ERP" trigger="hover">
                            <QuestionCircleOutlined className="link-text"/>
                        </Popover>
                        */}
                        </span>
                        }
                        name="erp_code"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập mã PO đã tạo trên Odoo ERP. Trong trường hợp đang làm đề nghị tạm ứng, nhập "N/A". Trong trường hợp tạo chi phí từ các cửa hàng chưa vận hành trên Odoo, nhập "Chưa có"'
                            }]}
                    >
                        <div>
                            <POTags tags={poTags} setTags={setPoTags}/>
                        </div>
                    </Form.Item>

                    <Form.Item
                        name="transfer_from_account"
                        label="Tài khoản đi tiền"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn tài khoản đi tiền.'
                            }]}
                    >
                        <Select
                            optionLabelProp="value"
                            //onChange={(value) => setBankInfoDisable(value === 'TM' || value === 'TCH')}
                            onChange={(value) => setBankInfoDisable(isCashPayment.includes(value))}
                        >
                            {OptionsWithDescription(config?.transfer_from_account, {
                                label: 'name',
                                value: 'name',
                                description: 'description'
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Đối tượng công nợ"
                        name="partner"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn đối tượng công nợ'
                            }
                        ]}
                    >
                        < AutoComplete
                            options={partnerData}
                            onSearch={(e) => handlePartnerSearch(e)}

                        >
                            <Input
                                placeholder="Nhập tên hoặc mã đối tác để tìm kiếm"
                                suffix={
                                    <Button
                                        size="small"
                                        type="text"
                                        icon={<SearchOutlined/>}
                                        loading={partnerLoading}
                                    />
                                }
                            />
                        </AutoComplete>
                    </Form.Item>

                    <Form.Item
                        label="Số tài khoản ngân hàng"
                        name="bank_account"
                        rules={!isCashPayment.includes(form.getFieldValue('transfer_from_account')) ? [
                            {
                                required: true,
                                message: 'Vui lòng nhập số tài khoản ngân hàng nhận tiền'
                            }] : null}

                    >
                        <Input disabled={bankInfoDisable}/>
                    </Form.Item>

                    <Form.Item
                        label="Tên tài khoản nhận tiền"
                        name="receiver_name"
                        //rules={form.getFieldValue('transfer_from_account') !== 'TM' ? [
                        rules={!isCashPayment.includes(form.getFieldValue('transfer_from_account')) ? [
                            {
                                required: true,
                                message: 'Vui lòng nhập tên Nhà cung cấp hoăc Người thụ hưởng'
                            }] : null}
                    >
                        <Input disabled={bankInfoDisable} onBlur={removeAccents}/>
                    </Form.Item>

                    <Form.Item
                        label="Ngân hàng"
                        name="bank_name"
                        //rules={form.getFieldValue('transfer_from_account') !== 'TM' ? [
                        rules={!isCashPayment.includes(form.getFieldValue('transfer_from_account')) ? [
                            {
                                required: true,
                                message: 'Vui lòng chọn ngân hàng'
                            }] : null}
                    >
                        <Select
                            //onSelect={onExpenseTypeSelect}
                            disabled={bankInfoDisable}
                            showSearch
                            optionLabelProp="value"
                            optionFilterProp="description"
                            options={config?.bank_list}
                            filterOption={(input, option) =>
                                (option?.value ?? '').toLowerCase().includes(input.toLowerCase()) ||
                                (option?.description ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            optionRender={(item) => (<OptionRender item={item.data} index={item.id} options={{
                                label: 'value',
                                description: 'description',
                                logo: 'logo'
                            }}/>)
                            }
                            onChange={() => setForeignBankActive(!foreignBankActive)}
                        >
                            {/*{OptionsWithDescription(config?.bank_list, {label: 'value', description: 'description'})}*/}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Tỉnh/Thành phố"
                        name="bank_province"
                        rules={(!isCashPayment.includes(form.getFieldValue('transfer_from_account'))) && (form.getFieldValue('bank_name') !== 'Ngân hàng nước ngoài') ? [
                            {
                                required: true,
                                message: 'Vui lòng chọn tỉnh/thành phố của Ngân hàng'
                            }] : null}
                    >
                        <Select
                            showSearch
                            disabled={bankInfoDisable}
                            optionLabelProp="name"
                            optionFilterProp="name"
                            options={config?.province?.map(item => {
                                return {
                                    label: item.name,
                                    value: item.name
                                }
                            })}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Chi nhánh"
                        name="bank_branch"
                        rules={!isCashPayment.includes(form.getFieldValue('transfer_from_account')) && (form.getFieldValue('bank_name') !== 'Ngân hàng nước ngoài') ? [{
                            required: true,
                            message: 'Vui lòng nhập chi nhánh ngân hàng'
                        }] : null}
                    >
                        <Input disabled={bankInfoDisable}/>
                    </Form.Item>

                    <Form.Item
                        label="Nội dung chuyển khoản"
                        name="bank_transfer_note"
                    >
                        <Input disabled={bankInfoDisable}/>
                    </Form.Item>

                    <Form.Item
                        label="Diễn giải"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập diễn giải cho đề nghị'
                            }]}
                    >
                        <TextArea
                            showCount
                            maxLength={1000}
                            style={{
                                height: 80,
                                resize: 'none',
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={<span>Phân loại thanh toán&nbsp;
                            <Popover
                                content={reformatNoteContent(config?.notes?.find(item => item.type === 'phanloai').note)}
                                title="Hướng dẫn nhập" trigger="hover">
                                <QuestionCircleOutlined className="link-text"/>
                            </Popover>
                            </span>}
                        name="instruction"
                    >
                        <TextArea
                            showCount
                            maxLength={500}
                            style={{
                                height: 80,
                                resize: 'none',
                            }}
                        />
                    </Form.Item>
                    <div style={{display: isSpecialCase}}>
                        <Form.Item
                            name="special_code"
                            label="Mã vụ việc"
                            rules={isSpecialCase === 'block' ? [
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mã vụ việc'
                                }] : null}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="special_season"
                            label="Mùa vụ"
                            rules={isSpecialCase === 'block' ? [
                                {
                                    required: true,
                                    message: 'Vui lòng chọn mùa vụ'
                                }] : null}
                        >
                            <Select
                                optionLabelProp="value"
                            >
                                {OptionsWithDescription(config?.season, {label: 'name', description: 'description'})}
                            </Select>
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="ref_document"
                        label="Tham chiếu chứng từ gốc"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="ref_contract"
                        label="Hợp đồng số"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="ref_invoice"
                        label="Hoá đơn số"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="request_amount"
                        label="Số tiền đề nghị"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập số tiền đề nghị thanh toán'
                            }]}
                    >
                        <InputNumber
                            style={{width: 250}}
                            onChange={handleRequestAmountChange}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            addonAfter={CurrencyOptions(config?.rates, handleCurrencyChange)}
                        />
                    </Form.Item>
                    <div style={{display: isExchange}}>
                        <Form.Item
                            name="foreign_to_vnd"
                            label="Quy đổi VNĐ"
                        >
                            <InputNumber
                                disabled
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                addonAfter={(
                                    <span>(Tỷ giá tạm tính: <strong>{formatNumber(exchangeRate)}</strong>)</span>)}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        name="request_payment_date"
                        label="Ngày thanh toán dự kiến"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn ngày dự kiến thanh toán'
                            }]}
                    >
                        <DatePicker
                            placeholder="Chọn ngày"
                            format="DD-MM-YYYY"
                            disabledDate={(current) => {
                                // Can not select days before today and today
                                //return current && current < dayjs().endOf('day');
                                //console.log(dayjs().startOf('day').format());
                                return current && current < dayjs().startOf('day').format();
                            }}

                        />
                    </Form.Item>

                    <Form.Item
                        name="accountant_id"
                        label="Kế toán nhận"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng chọn kế toán xử lý'
                            }]}
                    >
                        <Select
                            optionLabelProp="label"
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {OptionsWithDescription(config?.personnel, {
                                label: 'fullname',
                                value: 'uid',
                                description: 'position_title'
                            })}
                        </Select>
                    </Form.Item>

                    <div
                        style={{display: 'none'}}
                    >
                        <Form.Item
                            name="exchange_rate"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="currency_code"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="created_by_name"
                            initialValue={userInfo.name}
                        >
                            <Input/>

                        </Form.Item>
                    </div>
                </Form>
                <Flex
                    justify='space-between'
                >
                    <div>
                        <div
                            style={{
                                display: (form.getFieldValue('request_code') !== '') && (immutableFormData?.payment_lines?.length > 0) ? 'flex' : 'none'
                            }}
                        >
                            <Button
                                icon={<PrinterOutlined/>}
                                onClick={() => handleSubmit(true)}
                            >
                                In đề nghị
                            </Button>
                            <Popover
                                content="Chú ý: Nếu form có sửa đổi, bạn phải lưu form trước khi tiến hành in"
                            >
                                <QuestionCircleOutlined className="ms-2"/>
                            </Popover>
                        </div>
                    </div>
                    <Space>
                        <Button icon={<CloseOutlined/>} onClick={handleClose}>Bỏ qua</Button>
                        <Button style={{display: actionButtonEnabled ? 'flex' : 'none'}} icon={<SendOutlined/>}
                                type="primary"
                                onClick={() => handleSubmit(false)}>{editData ? 'Sửa đề nghị' : 'Gửi đề nghị'}
                        </Button>
                    </Space>
                </Flex>

                {/*
                <div style={{display: history?.length > 0 ? 'block' : 'none'}}>
                    <h4>Tiến trình xử lý:</h4>
                    <Timeline
                        items={
                            history?.map((item, index) => ({
                                color: item.color,
                                dot: item?.dot,
                                children: <span className="text-xs">{renderHistoryItem(item)}</span>
                            }))
                        }
                    />
                </div>
                */
                }
            </Drawer>
            {/*<PasteModal open={pasteModalOpen} onClose={() => setPasteModalOpen(false)} handlePaste={handlePaste}/>*/
            }
            <BulkRequestForm open={pasteModalOpen} onClose={() => setPasteModalOpen(false)} handlePaste={handlePaste}/>
            <FormPrintPreview data={form.getFieldsValue()} open={printPreviewOpen} setOpen={setPrintPreviewOpen}
                              lineData={immutableFormData?.payment_lines}/>
        </div>
    )
}

export default PaymentRequestForm
