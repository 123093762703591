import {Button, Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {useUserInfo} from "../../Helpers/user-session-helper";
import {useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import {notify} from "../../Helpers/toast-helper";
import dayjs from "dayjs";

const CashReport = () => {
    const userInfo = useUserInfo();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userInfo){
            form.setFieldsValue({
                store: userInfo?.department,
                reporter: userInfo?.name,
                job_title: userInfo?.position_title,
            })
        }
    }, [userInfo]);


    const formSubmit = async () => {
        try {
            const values = await form.validateFields()
            setLoading(true);
            api.post('/store/cash', values).then(res => {
                if (res) {
                    console.log("res", res)
                    notify('success', 'Gửi dữ liệu thành công');
                    form.setFieldValue('money', 0);
                }
            }).finally(() => {
                setLoading(false);
            })

        } catch (error) {
            notify('error', 'Vui lòng kiểm tra lại thông tin');
            console.log(error);
        }
    }

    return (
        <div>
            <h1>Báo cáo tiền mặt cửa hàng</h1>
            <Row>
                <Col span={12}>
                    <Form
                        layout="vertical"
                        form={form}
                    >
                        <Form.Item
                            name="store"
                            label="Cửa hàng"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập tên cửa hàng"
                                }
                            ]}
                        >
                            <Input
                                readOnly={true}
                            />
                        </Form.Item>

                        <Form.Item
                            name="reporter"
                            label="Người báo cáo"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập tên người báo cáo"
                                }
                            ]}
                        >
                            <Input
                                readOnly={true}
                            />
                        </Form.Item>

                        <Form.Item
                            name="job_title"
                            label="Vị trí"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập vị trí"
                                }
                            ]}
                        >
                            <Input
                                readOnly={true}
                            />
                        </Form.Item>

                        <Form.Item
                            name="report_date"
                            label="Ngày báo cáo"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn ngày báo cáo"
                                }
                            ]}
                            initialValue={dayjs()}
                        >
                            <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                defaultValue={dayjs()}
                                disabledDate={(current) => {
                                    return current && current > dayjs().endOf('day');
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="money"
                            label="Tiền mặt lưu cửa hàng"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập số tiền"
                                }
                            ]}
                        >
                            <InputNumber
                                className="w-100"
                                min={0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                            />
                        </Form.Item>
                    </Form>
                    <Button
                        type="primary"
                        onClick={formSubmit}
                        loading={loading}
                    >
                        Gửi thông tin
                    </Button>
                </Col>
                <Col span={12}></Col>
            </Row>
        </div>
    );
}

export default CashReport;