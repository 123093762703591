import {Button, Drawer, Flex, Form, Input, InputNumber, Popconfirm, Select} from "antd";
import {OptionRender} from "../../Helpers/common-helper";
import {UseSiteConfig} from "../../Helpers/config-helper";
import {useEffect, useState} from "react";
import {DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import api from "../../Helpers/api-helper";
import {removeVietnameseAccents} from "../PaymentRequest/helper";

const BankAccountEdit = ({open, onClose, bankInfo}) => {
    const defaultRules = {
        province_required: true,
    }

    const handleClose = () => {
        form.resetFields()
        setRules(defaultRules)
        onClose(true);
    }

    const [form] = Form.useForm();
    const {siteConfig} = UseSiteConfig();
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rules, setRules] = useState(defaultRules)

    const handleSave = async () => {
        try {
            const values = await form.validateFields();

            if (values.bank_name === 'Ngân hàng nước ngoài') {
                values.bank_province = '';
                values.bank_branch = '';
            }

            if (!bankInfo) {
                api.post('/bank-account', values).then(response => {
                    notify('success', 'Lưu thông tin thành công');
                    handleClose();
                }).finally(() => {
                    setLoading(false);
                })
            } else {
                api.patch('/bank-account', {
                    ...values,
                    _id: bankInfo._id
                }).then(response => {
                    notify('success', 'Sửa thông tin thành công');
                    handleClose();
                }).finally(() => {
                    setLoading(false);
                })
            }
        } catch (e) {
            notify('error', 'Vui lòng kiểm tra lại thông tin');
            console.log(e)
        }
    }

    useEffect(() => {
        if (bankInfo) {
            form.setFieldsValue(bankInfo);

            if (bankInfo.bank_name === 'Ngân hàng nước ngoài') {
                setRules({
                    province_required: false
                })
            }
        }
    }, [bankInfo])

    const handleDelete = () => {
        api.delete(`/bank-account/${bankInfo._id}`, ).then(response => {
            if (response) {
                notify('success', 'Xóa thông tin thành công');
                handleClose();
            }
        })
    }

    useEffect(() => {
        setConfig(siteConfig);
    }, [siteConfig])

    const removeAccents = (e) => {
        const value = e.target.value.trim();
        form.setFieldsValue({receiver_name: removeVietnameseAccents(value).toUpperCase()})
    }

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            maskClosable={false}
            width={500}
            title={bankInfo ? 'Chỉnh sửa mẫu tài khoản' : 'Thêm tài khoản mẫu'}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="bank_alias"
                    label="Tên gợi nhớ"
                >
                    <Input
                        placeholder="Tên gợi nhớ (nickname)"
                    />
                </Form.Item>

                <Form.Item
                    name="receiver_name"
                    label="Tên tài khoản"
                    rules={[
                        {
                            required: rules.province_required,
                            message: 'Vui lòng nhập tên người nhận/tổ chức'
                        }
                    ]}
                    onBlur={removeAccents}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="bank_account"
                    label="Số tài khoản"
                    rules={[
                        {
                            required: rules.province_required,
                            message: 'Vui lòng nhập số tài khoản'
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="bank_name"
                    label="Tại ngân hàng"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng chọn ngân hàng'
                        }
                    ]}
                >
                    <Select
                        showSearch
                        optionLabelProp="value"
                        optionFilterProp="description"
                        options={config?.bank_list}
                        filterOption={(input, option) =>
                            (option?.value ?? '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ||
                            (option?.description ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        optionRender={(item) => (<OptionRender item={item.data} index={item.id} options={{
                            label: 'value',
                            description: 'description',
                            logo: 'logo'
                        }}/>)}
                        onChange={(value) => {
                            setRules({
                                province_required: value !== 'Ngân hàng nước ngoài'
                            })

                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="bank_province"
                    label="Tỉnh/Thành phố"
                    rules={[
                        {
                            required: rules.province_required,
                            message: 'Vui lòng chọn tỉnh/thành phố'
                        }
                    ]}
                >
                    <Select
                        showSearch
                        disabled={!rules.province_required}
                        optionLabelProp="name"
                        optionFilterProp="name"
                        options={config?.province?.map(item => {
                            return {
                                label: item.name,
                                value: item.name
                            }
                        })}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="bank_branch"
                    label="Chi nhánh"
                    rules={[
                        {
                            required: rules.province_required,
                            message: 'Vui lòng chọn chi nhánh'
                        }
                    ]}
                >
                    <Input
                        disabled={!rules.province_required}
                    />
                </Form.Item>
                <Flex
                    justify="space-between"
                >
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa tài khoản này không?"
                        onConfirm={() => {
                            handleDelete()
                        }}
                        cancelText="Bỏ qua"
                    >
                        <Button
                            disabled={!bankInfo}
                            type="text"
                            danger
                            icon={<DeleteOutlined/>}
                        />
                    </Popconfirm>
                    <Button
                        type="primary"
                        icon={<SaveOutlined/>}
                        onClick={handleSave}
                        loading={loading}
                    >
                        Lưu thông tin
                    </Button>
                </Flex>
            </Form>
        </Drawer>
    )
}

export default BankAccountEdit;